angular.module('kljDigitalLibraryApp')
    .controller('CaseDetailsCtrl', ["$scope", "$uibModalInstance", "desc",
        function($scope, $uibModalInstance, desc) {

            $scope.text = desc.title;
            $scope.title = desc.text;
            $scope.close = function() {
                $uibModalInstance.dismiss('cancel');
            };

        }
    ]);